<template>
    <b-container class="py-4">
        <b-modal
            id="order"
            centered
            no-close-on-backdrop
            hide-footer
            no-close-on-esc
            size="lg"
        >
        <template #modal-header="{ close }">
            <div class="py-2 d-flex justify-content-between align-items-center w-100">
              <slot name="header">
                <h5 class="text-wite">{{ $t('order.changeOrderStatus') }}</h5>
              </slot>
              <div class="d-flex justify-content-end align-self-start pt-2 font-size-22">
                <i class="las la-times cursor-pointer" @click="close()"></i>
              </div>
            </div>
        </template>
            <div class="p-4">
                <ValidationObserver v-slot="{ handleSubmit }">
                    <b-form
                      ref="form"
                      @submit.prevent="handleSubmit(addComment)"
                    >
                      <b-row class="mb-3">
                        <b-col lg="12" class="mb-1">
                          <b-form-group label-for="Message">
                            <ValidationProvider
                              name="FMessage"
                              ref="Message"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-form-textarea
                                :placeholder="$t('forms.msg')+'..'"
                                rows="2"
                                :validate="'required'"
                                v-model="reply"
                                :class="[
                                  'curva-textarea',
                                  errors.length > 0 ? ' is-invalid' : '',
                                ]"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12">
                          <b-button
                            v-if="!loading"
                            class="curva-granola-btn small-rounded-btn"
                            type="submit"
                            block
                            >{{ $t('forms.send') }}</b-button>
                          <b-button
                          v-else
                          class="curva-granola-btn small-rounded-btn"
                          block
                          ><spinner-loading /></b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </ValidationObserver>
            </div>
        </b-modal>
        <b-row class="d-flex justify-content-between" v-if="!loadingPage">
            <b-col cols="12">
            <div class="d-flex justify-content-start mb-3 flex-wrap" style="gap: 5px" v-if="invoice.status == 'waiting customer'">
                <b-button variant="warning" type="button" style="flex-grow: 1" v-b-modal.order @click="status = 'late'">
                  <span class="font-size-16 text-black">{{ $t('status.lateRequest') }}</span>
                </b-button>
                <b-button variant="info" type="button" style="flex-grow: 1" v-b-modal.order @click="status = 'edit'">
                  <span class="font-size-16 text-black">{{ $t('status.editRequest') }}</span>
                </b-button>
                <b-button variant="danger" type="button" style="flex-grow: 1" v-b-modal.order @click="status = 'cancel'">
                  <span class="font-size-16">{{ $t('status.cancelRequest') }}</span>
                </b-button>
                <b-button variant="success" style="flex-grow: 1" type="button" v-b-modal.order @click="status = 'confirm'">
                  <span class="font-size-16 text-black">{{ $t('status.confirmRequest') }}</span>
                </b-button>
            </div>
            </b-col>
            <b-col lg="8">
                <iq-card class="px-3 py-2 iq-style3 d-flex justify-content-between align-items-center gap_1 flex-wrap">
                    <span class=" d-flex justify-content-between align-items-center gap_1 font-size-16 text-warning">
                      <span class="text-gray">{{ $t('main.ordernumber') }}</span>
                      {{ invoice.id }}
                    </span>
                    <span>
                      <b-badge class="category_badge px-3 py-2 d-flex gap_1 text-gray font-size-16 font-weight-light"
                            > {{ $t('order.status') }}
                      <span class="text-warning">{{$t(`orderStatus.${invoice.status}`)}}</span></b-badge>
                    </span>
                  </iq-card>
                  <iq-card class="px-3 py-2 iq-style3">
                    <div class="d-flex justify-content-between align-items-center gap_1 flex-wrap">
                      <span class="d-flex justify-content-between align-items-center gap_1 font-size-16 text-warning">
                        <span class="text-gray">{{ $t('forms.name') }}</span>
                        {{ invoice.client_name }}
                      </span>
                      <span class="d-flex justify-content-between align-items-center gap_1 font-size-16 text-warning">
                        <span class="text-gray">{{ $t('order.created_at') }}</span>
                        {{ invoice.created_at }}
                      </span>
                    </div>
                    <div class="d-flex my-2 justify-content-between align-items-center gap_1 flex-wrap">
                      <span class="d-flex justify-content-between align-items-center gap_1 font-size-16 text-warning">
                        <span class="text-gray">{{ $t('forms.phone') }}</span>
                        {{ invoice.address.phone1 }}
                      </span>
                      <span class="d-flex justify-content-between align-items-center gap_1 font-size-16 text-warning">
                        <span class="text-gray">{{ $t('forms.phone2') }}</span>
                        {{ invoice.address.phone2 }}
                      </span>
                    </div>
                    <span class="flex-none">
                        <p class="text-initial">
                          <i class="text-warning las la-map-marker-alt mr-2"></i>
                          {{invoice.address.region ? invoice.address.region[`name_${$i18n.locale}`] : '' }} , {{invoice.address.city ? invoice.address.city[`name_${$i18n.locale}`] : ''}} , {{ invoice.address.street }} ,
                          {{ $t('forms.block_no')}} {{invoice.address.block_no}} ,  {{ $t('forms.flat_no')}} {{invoice.address.flat_no}} , {{ $t('forms.floor_no')}} {{invoice.address.floor_no}}
                        </p>
                    </span>
                  </iq-card>
                  <template v-if="invoice.all_products && invoice.all_products.length">
                  <p class="text-initial">
                    <curva-title class="mb-1" :title="$t('main.orderProducts')"/>
                  </p>
                  <b-table responsive show-empty
                  :items="invoice.all_products" :fields="tableHeader" primary-key="id"
                  class="curva-table" tbody-tr-class="curva-table-row">
                  <template #cell(name)="data">
                    <router-link :to="{name: 'productDetails', params: {id: data.item.code}}" class="d-flex align-items-center">
                      <img :src="data.item.image" class="table-img mx-2">
                      <span style="max-width: 200px; display:inline-block; text-wrap: wrap; white-space: wrap;">{{ data.item.name }}</span>
                    </router-link>
                  </template>
                  </b-table>
                </template>

                  <template v-if="invoice.all_retrievals && invoice.all_retrievals.length">
                        <p class="text-initial">
                          <curva-title class="mb-1" :title="$t('main.retrievalProducts')"/>
                        </p>
                        <b-table responsive show-empty
                        :items="invoice.all_retrievals" :fields="tableHeader" primary-key="id"
                        class="curva-table" tbody-tr-class="curva-table-row">
                        <template #cell(name)="data">
                          <router-link :to="{name: 'productDetails', params: {id: data.item.code}}">
                              <img :src="data.item.image" class="table-img mx-2">
                          <span>{{ data.item.name.length > 20 ? data.item.name.substring(0,20)+'...' : data.item.name }}</span>
                          </router-link>
                        </template>
                          </b-table>
                    </template>
            </b-col>
            <b-col lg="4">
              <div class="order-summary">
                <p class="title text-center">{{ $t('cart.summary') }}</p>
                <b-row>
                  <b-col class="text-start">{{ $t('cart.tItems') }}</b-col>
                  <b-col class="text-end" v-if="invoice.all_products">
                   {{ invoice.all_products.reduce(
                    (accumulator, currentValue) => accumulator + currentValue.quantity,
                    0,
                  ) }} {{ $t('order.items') }}
                  </b-col>
                </b-row>
                 <b-row>
                  <b-col  class="text-start">{{ $t('cart.subtotal') }}</b-col>
                  <b-col class="text-end">
                    <span>{{ invoice.total_cost}}
                      <span class="currency"> {{$t('egp')}}</span>
                    </span>
                  </b-col>
                </b-row>
               <b-row>
                  <b-col class="text-start">{{ $t('cart.fees') }}</b-col>
                  <b-col class="text-end">
                    <span> {{ invoice.shipping_cost }}
                      <span class="currency"> {{$t('egp')}}</span></span>
                  </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-start">{{ $t('cart.tax') }}</b-col>
                    <b-col class="text-end">
                      0<span class="currency"> {{$t('egp') }}</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-start">{{ $t('cart.discount') }}</b-col>
                    <b-col class="text-end">
                      {{ invoice.discount }}<span class="currency"> {{$t('egp') }}</span>
                    </b-col>
                  </b-row>
                <b-row class="total-row">
                  <b-col class="text-start">{{ $t('cart.total') }}</b-col>
                  <b-col class="text-end">{{invoice.final_cost}}
                    <span class="currency">{{$t('egp')}}</span>
                  </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-start">{{ $t('cart.paid') }}</b-col>
                    <b-col class="text-end">
                      <span> {{ invoice.paid }}
                        <span class="currency"> {{$t('egp')}}</span></span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-start">{{ $t('cart.remain') }}</b-col>
                    <b-col class="text-end">
                      <span class="text-danger"> {{ invoice.remain }} <span class="currency">{{$t('egp')}}</span></span>
                    </b-col>
                  </b-row>
                  <b-row v-if="invoice.retrievals_cost">
                    <b-col class="text-start">{{ $t('cart.retrievals_cost') }}</b-col>
                    <b-col class="text-end">
                      <span class="text-warning"> {{ invoice.retrievals_cost }} <span class="currency"> {{$t('egp')}}</span></span>
                    </b-col>
                  </b-row>
              </div>
            </b-col>
            <b-col md="12">
              <div class="d-flex justify-content-center">
                <img :src="invoice.image" class="m-auto" />
              </div>
            </b-col>
        </b-row>
        <div v-else>
          <spinner-loading />
        </div>
    </b-container>
  </template>

<script>
import { core } from '@/config/pluginInit'
import Api from '@/axios'
import spinnerLoading from '../components/spinnerLoading.vue'

export default {
  components: { spinnerLoading },
  data () {
    return {
      invoice: {},
      tableHeader: [
        { key: 'name', label: this.$t('cart.name'), class: 'px-3 text-initial' },
        { key: 'size', label: this.$t('cart.size'), class: 'px-3 text-center' },
        { key: 'color', label: this.$t('productDetails.color'), class: 'px-3 text-center' },
        { key: 'barcode', label: this.$t('cart.barcode'), class: 'px-3 text-center' },
        { key: 'unit_price', label: this.$t('cart.unitPrice'), class: 'px-3 text-center' },
        { key: 'quantity', label: this.$t('cart.quantity'), class: 'px-3 text-center' }
      ],
      allStatus: ['confirm', 'edit', 'late', 'cancel'],
      status: '',
      reply: '',
      loading: false,
      loadingPage: true
    }
  },
  methods: {
    getInvoice () {
      this.loadingPage = true
      return Api().get(`order-details?code=${this.$route.params.client_id}-${this.$route.params.order_id}`).then(response => {
        this.invoice = response.data.data
        this.loadingPage = false
      }).catch(error => {
        console.log(error)
        this.loadingPage = false
      })
    },
    addComment () {
      this.loading = true
      return Api().post('order-details', {
        order: this.invoice.id,
        status: this.status,
        reply: this.reply
      }).then(response => {
        core.showSnackbar('success', response.data.message)
        this.getInvoice()
        this.loading = false
        this.invoice = response.data.data
        this.$bvModal.hide('order')
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
    }
  },
  created () {
    this.getInvoice()
  },
  mounted () {
    core.index()
  }
}
</script>
